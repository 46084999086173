import Im from "immutable";
import Moment from "moment";
import React, { useState, useEffect } from "react";

import { ErrorBoundary } from "storybook-dashboard/errors";
import {getCompanyReportingPeriods} from "storybook-dashboard/dashboard/project/utils";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues";
import IndicatorsRAGPanel from "storybook-dashboard/dashboard/ragPanels/indicators";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import IndicatorPerformanceTable from "storybook-dashboard/dashboard/performanceTable";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import DashboardFilters from "storybook-dashboard/dashboard/reportFilters"
import {ReportingPeriodRangePicker} from "storybook-dashboard/dashboard/components/reportingPeriodRangePicker";



export default function DashboardComponents({ companyId, company, iconPath, downloadExcel,  }) {
  let [selectedIssue, setSelectedIssue] = useState(null);
  let [selectedIndicatorIds, setSelectedIndicatorIds] = useState(Im.Map());
  let [filters, setFilters] = useState(Im.Map());

  // Reset the selected indicators to none if the issue selection changes
  useEffect(() => {
    setSelectedIndicatorIds(Im.Map());
  }, [selectedIssue]);

  // Get a list of only the selected indicators
  let selectedIndicators = selectedIndicatorIds.toList()

  return (
    <ErrorBoundary>
      <ErrorBoundary>
        <ReportingPeriodRangePicker
          filters={filters}
          setFilters={setFilters}
        />
      </ErrorBoundary>

      <div className="row">
        <ErrorBoundary>
          <DashboardFilters
            companyId={companyId}
            onApply={values => setFilters(values)}
            appliedFilters={filters}
          />
        </ErrorBoundary>
      </div>

      <ErrorBoundary>
        <IssuesRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          selected={selectedIssue}
          setSelected={setSelectedIssue}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          issue={selectedIssue}
          selected={selectedIndicatorIds}
          setSelected={setSelectedIndicatorIds}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsBarChart companyId={companyId} indicators={selectedIndicators} downloadExcel={downloadExcel} filters={filters} />
      </ErrorBoundary>

      <ErrorBoundary>
        <DataExplorer
          companyId={companyId}
          indicators={selectedIndicators}
          options={{
            metric_values: {
              text: "Performance breakdown table",
              component: IndicatorPerformanceTable,
            },
            performance: {
              text: "Input values table",
              component: InputValuesTable,
            },
          }}
        />
      </ErrorBoundary>
    </ErrorBoundary>
  );
}
