import React, { useState, useEffect } from "react";
import Im from "traec/immutable";
import { connect } from "react-redux";
import Traec from "traec";

import BaseFormConnected from "traec-react/utils/form";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { ProjectPermission } from "traec/utils/permissions/project";
import { Spinner } from "traec-react/utils/entities";
import { workPackageDetailsFields } from "./form";
import { BreadCrumb, getProjectProps } from "AppSrc/project/utils";
import Moment from "moment";
import WorkPackageList from "./wppanel";

import { setAndShowModal } from "AppSrc/utils/modal";
import { SetMetaDataFields } from "AppSrc/forms/meta";
import { getTerm } from "AppSrc/tree/utils";
import Octicon from "react-octicon";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Swal from "sweetalert2";

const getPreDispatchHook = () => {
  return action => {
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    //action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => state;
    console.log("Calling tracker_dispatch for CLEAR_CACHE data", action);
    return action;
  };
};

const clearDashboardCache = props => {
  let { trackerId, refId } = props;
  let fetch = new Traec.Fetch(
    "tracker_dispatch",
    "post",
    { trackerId, refId },
    { preDispatchHook: getPreDispatchHook(props) }
  );
  fetch.updateFetchParams({
    body: {
      type: "SUSTOOL_DASHBOARD_DATA",
      payload: {
        part: "CLEAR_CACHE",
        ref_id: refId
      }
    }
  });
  console.log(`Dispatching clearDashboardCache with refId: ${refId}`);
  fetch.dispatch();
};

const _pullFromTemplate = ({trackerId, refId, pullDescendants, keepTargets}) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId, refId });
  let formData = new FormData();
  formData.append("type", "PULL_FROM_TEMPLATE");
  formData.append(
    "payload",
    JSON.stringify({
      ref_id: refId,
      pull_descendants: pullDescendants,
      keep_targets: keepTargets
    })
  );

  fetch.updateFetchParams({
    body: formData,
    postSuccessHook: () => {
      location.reload();
    }
  });
  fetch.dispatch();
}

const pullFromTemplate = ({ trackerId, refId, tracker, cref, pullDescendants = false }) => {
  
  Swal.fire({
    title: "Re-sync with new template?",
    //html: "Would you like to re-sync the metrics with this new template?",
    type: "success",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Re-sync with template",
    cancelButtonText: "Cancel",
    input: "checkbox",
    inputValue: 1,
    inputPlaceholder: "Do not sync template targets"
  }).then(result => {
    if ("value" in result) {
      _pullFromTemplate({trackerId, refId, pullDescendants, keepTargets: Boolean(result.value)})
    } else {
      console.log("Skipping re-sync step");
    }
  });
};

function RenderID({ title, id }) {
  return (
    <tr>
      <td>
        <b>{title}</b>
      </td>
      <td>{id}</td>
    </tr>
  );
}

const setTrackerTemplate = (trackerId, templateId) => {
  console.log("Changing source template for tracker", trackerId, "to", templateId);

  let fetch = new Traec.Fetch("tracker", "patch", { trackerId });
  fetch.updateFetchParams({
    body: { from_template: templateId || null },
    postSuccessHook: () => {
      if (!templateId) {
        location.reload();
      } else {
        Swal.fire({
          title: "Re-sync with new template?",
          html:
            "The template has been updated.  Would you like to re-sync the metrics with this new template?<br/><br/><b>NOTE: This will only re-sync the metrics at THIS level. Not in other reporting packages of this project.</b>  You must do that manually by going to the settings page of each reporting package and pressing the re-sync with template button",
          type: "success",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Re-sync with template",
          cancelButtonText: "No thanks. I'll do it later.",
          input: "checkbox",
          inputValue: 1,
          inputPlaceholder: "Also resync reporting packages"
        }).then(result => {
          if ("value" in result) {
            pullFromTemplate({
              trackerId,
              pullDescendants: Boolean(result.value)
            });
          } else {
            console.log("Skipping re-sync step");
            location.reload();
          }
        });
      }
    }
  });

  fetch.dispatch();
};

function SetSourceTemplate(props) {
  let { trackerId, templateId } = props;
  let [templates, setTemplates] = useState(Traec.Im.List());
  let [options, setOptions] = useState(Traec.Im.List());

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [
        new Traec.Fetch(
          "tracker",
          "list",
          {},
          {
            preUpdateHook: args => ({ ...args, onlyTemplates: true }),
            preDispatchHook: action => {
              action.fetchParams.postSuccessHook = data => {
                console.log("Got tracker templates", data);
                let _options = Traec.Im.fromJS(data)
                  .toList()
                  .map(i =>
                    Traec.Im.fromJS({
                      value: i.get("uid"),
                      name: i.getInPath("project.name")
                    })
                  );
                setOptions(_options);
              };
              return action;
            }
          }
        )
      ]
    });
  }, []);

  let opts = options
    .sortBy(i => i.get("name"))
    .map((opt, i) => (
      <option key={i} value={opt.get("value") || ""}>
        {opt.get("name")}
      </option>
    ))
    .unshift(
      <option key={-1} value={""}>
        None
      </option>
    );

  console.log("Rendering template select", templateId);
  return (
    <ErrorBoundary>
      <p>Select the source template</p>
      <select className="form-control" value={templateId} onChange={e => setTrackerTemplate(trackerId, e.target.value)}>
        {opts}
      </select>
    </ErrorBoundary>
  );
}

const editSourceTemplate = props => {
  let { trackerId, templateId } = props;
  console.log("Editing source template for tracker ID", trackerId, templateId);

  let modalId = "EditTemplateModal001";

  setAndShowModal(modalId, {
    title: "Edit Source Template",
    body: <SetSourceTemplate {...props} />
  });
};

export function TechnicalDetails(props) {
  let { projectId, tracker, trackerId, refId, isRootRef } = props;
  let trackerTemplateId = tracker?.get("from_template");
  return (
    <ErrorBoundary>
      <BSCard
        widthOffset="col-sm-12"
        title="Technical details"
        body={
          <table width="100%">
            <tbody>
              <RenderID title="Full Project ID" id={projectId} />
              <RenderID title="Full Metric Tree ID" id={trackerId} />
              <RenderID title="Full Report Package ID" id={refId} />
              <RenderID
                title={
                  <span>
                    Source Template ID
                    {isRootRef ? (
                      <span
                        className="ml-3"
                        style={{ cursor: "pointer" }}
                        onClick={e => editSourceTemplate({ trackerId, templateId: trackerTemplateId })}
                      >
                        <Octicon name="pencil" />
                      </span>
                    ) : null}
                  </span>
                }
                id={trackerTemplateId}
              />
              <tr style={{ paddingTop: "0.5rem" }}>
                <td>
                  <button className="btn btn-sm btn-warning mr-3" onClick={() => clearDashboardCache(props)}>
                    Clear dashboard cache
                  </button>
                  {trackerTemplateId ? (
                    <button className="btn btn-sm btn-outline-danger" onClick={() => pullFromTemplate(props)}>
                      Re-sync with template
                    </button>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </ErrorBoundary>
  );
}

const toggleStatus = (e, cref) => {
  e.preventDefault();
  let crefId = cref.get("uid");
  let commit = cref.get("latest_commit");

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: crefId,
    commitId: commit.get("uid")
  });

  fetch.updateFetchParams({
    body: {
      comment: "On hold status toggled",
      status: "HOLD"
    },
    postSuccessHook: () => {
      location.reload();
    }
  });

  fetch.dispatch();
};

export function ReportPeriodString(item) {
  return `${Moment(item.get("startDate")).format("Do MMM YY")} to ${Moment(item.get("endDate"))
    .add(-1, "days")
    .format("Do MMM YY")}`;
}

function OnHoldButton({ cref }) {
  if (!cref) {
    return null;
  }

  let commit_status = cref.getInPath("latest_commit.status.name");
  let on_hold = commit_status ? (commit_status.startsWith("Not for") ? true : false) : false;

  return (
    <div className="row mb-2">
      <div className="col-sm-12">
        <BSBtn
          text={on_hold ? "On Hold" : "Active"}
          onClick={e => toggleStatus(e, cref)}
          extra_className={on_hold ? "bg-danger" : "bg-success"}
          noFloatRight={true}
        />
        <small className="form-text text-muted">Click to toggle on-hold status</small>
      </div>
    </div>
  );
}

const hasReportOnRef = (reportingPeriod, refId) => {
  return (reportingPeriod.get("commits") || Traec.Im.List()).filter(i => i.getInPath("ref.uid") == refId).size > 0;
};

class RefDetails extends React.Component {
  constructor(props) {
    super(props);

    let { refId } = props.match.params;
    let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId: null, refId });

    this.state = {
      formParams: fetch.params,
      initFormFields: Im.Map(),
      setInitFields: false,
      isTemplate: false,
      isPublic: false,
      setStateFromTracker: false
    };

    this.requiredFetches = [
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("project_reporting_periods", "list")
    ];

    this.setPostData = this.setPostData.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
    this.setFormParams();
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    // Set the initial form fields based on values in the Project
    let { tracker, trackerId, refId } = this.props;

    if (tracker && !this.state.setStateFromTracker) {
      let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId, refId });
      this.setState({
        setStateFromTracker: true,
        formParams: fetch.params
      });
    }

    // Update the form parameters
    if (prevProps.trackerId !== trackerId || prevProps.refId !== refId) {
      this.setFormParams();
    }
  }

  setFormParams() {
    let { trackerId, refId } = this.props;
    let fetch = new Traec.Fetch("tracker_ref", "patch", { trackerId, refId });
    this.setState({ formParams: fetch.params });
  }

  setInitFields() {
    let { cref, disciplines, projectReportingPeriods } = this.props;

    // Are we currently on a work package?
    if (!cref) {
      return workPackageDetailsFields;
    }

    let latestCommit = cref.get("latest_commit");
    //console.log("setInitFields 001 latest_commit", latestCommit.toJS());
    //console.log('disciplines', disciplines.toJS())

    let setFields = {};
    Object.assign(setFields, workPackageDetailsFields);

    setFields.name.value = cref.get("name");
    //console.log('set fields 01', setFields);

    setFields.commit__discipline.value = latestCommit.get("discipline");
    if (disciplines) {
      setFields.commit__discipline.options = disciplines
        .map((element, i) => (
          <option key={i} value={element.get("base_uid")}>
            {element.get("name")}
          </option>
        ))
        .toArray();
      // Push on an empty option for null
      setFields.commit__discipline.options.unshift(<option key={-1} value={""} />);
    }
    //
    if (projectReportingPeriods) {
      let curId = latestCommit.get("reporting_period");
      setFields.commit__reporting_period.value = curId;
      // Sort the reporting periods
      let prList = projectReportingPeriods
        .toList()
        .filter(i => !hasReportOnRef(i, cref.get("uid")) || i.get("uid") == curId)
        .sortBy(i => i.get("startDate"))
        .filter(
          i =>
            i.getInPath("summary.latest_commit") == null ||
            i.getInPath("summary.latest_commit") != latestCommit.get("reporting_period")
        );
      // Format the options
      let currentReportingPeriodId =
        latestCommit?.getInPath("reporting_period_data.uid") || latestCommit?.get("reporting_period") || "";
      setFields.commit__reporting_period.value = currentReportingPeriodId;
      // Set the options
      setFields.commit__reporting_period.options = prList
        .map((item, i) => (
          <option key={i} value={item.get("uid")}>
            {ReportPeriodString(item)}
          </option>
        ))
        .toArray();
      setFields.commit__reporting_period.options.unshift(<option key={-1} value={""} />);
    }

    //
    setFields.commit__due_date.value = latestCommit.get("due_date");

    //console.log('set fields 02', setFields);
    return setFields;
  }

  setPostData(post) {
    // Get the fields that are prefixed with meta_ and put them into the meta_json object
    let postData = {};
    let metaData = {};
    let commitData = {};
    let commitMetaData = {};
    for (let [key, value] of Object.entries(post)) {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        Object.assign(metaData, { [metaKey]: value });
      } else if (key.startsWith("commit__")) {
        let commitKey = key.split("commit__")[1];
        if (commitKey.startsWith("meta_")) {
          Object.assign(commitMetaData, { [commitKey]: value });
        } else {
          Object.assign(commitData, { [commitKey]: value });
        }
      } else {
        Object.assign(postData, { [key]: value });
      }
    }
    Object.assign(commitData, { meta_json: commitMetaData });
    Object.assign(postData, {
      meta_json: metaData,
      latest_commit: commitData
    });
    console.log("DATA", postData, post);
    return postData;
  }

  render() {
    let { company, cref, refId, project, projectId, tracker, rootRef, isRootRef, tenant_meta } = this.props;

    if (!project || !cref) {
      return <Spinner title="Loading..." explanation="" />;
    }

    let rp_term = getTerm("Reporting Package", this.props);

    // Check the User permissions for this project
    return (
      <React.Fragment>
        <h3>{isRootRef ? "Edit Project Details" : `Edit ${rp_term} Details`}</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />

        <OnHoldButton cref={cref} />

        <div className="row">
          <BSCard
            widthOffset="col-sm-12"
            title={`${rp_term} Details`}
            body={
              <BaseFormConnected
                params={this.state.formParams}
                fields={this.setInitFields()}
                prePostHook={this.setPostData}
                forceShowForm={true}
                hideUnderline={true}
              />
            }
          />
        </div>

        <ErrorBoundary>
          <div className="row">
            <BSCard
              widthOffset="col-sm-12"
              title="Meta data"
              body={
                <SetMetaDataFields
                  saveMetaFetchProps={{
                    handler: "tracker_ref_commit",
                    method: "patch",
                    params: {
                      trackerId: cref.get("tracker"),
                      refId: cref.get("uid"),
                      commitId: cref.getInPath("latest_commit.uid")
                    }
                  }}
                  metaJson={cref.getInPath("latest_commit.meta_json")}
                />
              }
            />
          </div>
        </ErrorBoundary>

        <ProjectPermission projectId={projectId} requiresAdmin={true}>
          <div className="row">
            <WorkPackageList
              project={project}
              tracker={tracker}
              rootRef={rootRef}
              cref={cref}
              showMenu={true}
              tenant_meta={tenant_meta}
            />
          </div>
        </ProjectPermission>

        <div className="row">
          <ErrorBoundary>
            <TechnicalDetails {...this.props} />
          </ErrorBoundary>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //const { _projectId, _refId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  // Don't parse anything until we have everything we need and the refIds match the URL
  let { _refId } = ownProps.match.params;
  if (_refId && !refId) {
    return {};
  }

  // Now get the project props
  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );

  // Get the disciplines for this work package
  let disciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`);

  // Get the reporting periods for this project
  let projectReportingPeriods = crefId
    ? state.getInPath(`entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`)
    : null;

  let tenant_meta = state.getInPath(`entities.tenant.meta_json`) || Traec.Im.Map();

  return {
    company,
    projectId,
    project,
    tracker,
    trackerId,
    refId,
    isRootRef,
    cref,
    rootRef,
    disciplines,
    projectReportingPeriods,
    tenant_meta
  };
};

export default connect(mapStateToProps)(RefDetails);
