import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { DetailedIconChart } from "AppSrc/charts";
import { BSCard } from "traec-react/utils/bootstrap";
import { generateIndicatorChart, generateIndicatorTargetMap } from "./utils";
import { CumulativeButton } from "./sustainabilityPanel/utils";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { sortAndCropData, dataToState, getFetchBody, getDispatchFetches } from "./sustainabilityPanel/helpers";

import ProjectReportCommitResults from "AppSrc/dashboards/lists/projectCommits";
//import ProjectReportInputValues from "AppSrc/dashboards/lists/projectInputValues";
import ProjectReportInputValues from "storybook-dashboard/dashboard/project/inputValues"

import CompanyReportProjectResults from "AppSrc/dashboards/lists/companyProjects";
import CompanyReportIndicatorValues from "AppSrc/dashboards/lists/companyInputValues";

import IndicatorHorizontalBarChart from "./indicatorBarHorizontal";
import IndicatorVerticalBarChart from "./indicatorBarVertical";

const getIndicator = indicators => {
  let indicator;
  let indicatorId;
  if (indicators && indicators.size == 1) {
    indicator = indicators.first();
    indicatorId = indicator.get("_key");
    indicator = indicator.set("uid", indicatorId);
  }
  return { indicator, indicatorId };
};

const CompanyContributions = props => {
  const [tables, setTables] = useState("");
  let { selected, hide, indicator } = props;

  if (hide || !indicator || selected?.size > 1) {
    return null;
  }

  //console.log("Rendering CompanyContributions", props);

  let indicatorName = indicator?.get("name");
  let indicatorId = indicator?.get("uid") || indicator?.get("_key");

  //BS Select in Traec react utils bootstrap
  console.log("selected tables ==>", tables);
  return (
    <React.Fragment>
      <div className="dropdown btn-group col mb-5 mt-5">
        <button
          className="btn btn-dark dropdown-toggle btn-lg col"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Data Explorer 🔍 Click to explore your data
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          style={{ cursor: "pointer" }}
          onClick={e => {
            console.log("target.value ===>", e.target.value);
            console.log("tables state ==>", tables);
            e.target.value === "" || !e.target.value ? setTables("") : setTables(e.target.value);
          }}
          onChange={e => {
            console.log("selected dropdown value ==>", e.target.value);
          }}
        >
          <option className="dropdown-item" value={"performance"}>
            🔢 Performance breakdown table
          </option>
          <option className="dropdown-item" value={"metric_values"}>
            🔢 Input values table
          </option>
          {/* <option className="dropdown-item" value={"pie_chart"}>
            🥧 Metrics pie chart
          </option> */}
        </div>
      </div>

      {/* //For displaying indicator data regarding company */}
      <div className="row mb-3">
        <ErrorBoundary>{tables === "pie_chart" ? <DoughnutPieChart /> : null}</ErrorBoundary>
      </div>
      <div className="row">
        <ErrorBoundary>
          {tables === "metric_values" ? (
            <CompanyReportIndicatorValues {...props} indicator={indicator} indicatorId={indicatorId} />
          ) : null}
        </ErrorBoundary>
      </div>
      <div className="row">
        <ErrorBoundary>
          {tables === "performance" ? (
            <CompanyReportProjectResults
              {...props}
              cumulation="current"
              indicatorOnly={true}
              indicator={indicator}
              indicatorId={indicatorId}
            />
          ) : null}
        </ErrorBoundary>
      </div>
    </React.Fragment>
  );
};

const ProjectContributions = props => {
  const [projTables, setProjTables] = useState("");

  let { selected, hide, indicator, isRootRef } = props;
  if (hide || !indicator || selected?.size > 1) {
    return null;
  }

  let indicatorName = indicator?.get("name");
  let indicatorId = indicator?.get("uid") || indicator?.get("_key");

  return (
    <React.Fragment>
      <div className="dropdown btn-group col mb-5 mt-5">
        <button
          className="btn btn-dark dropdown-toggle btn-lg col mb-10"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Data Explorer 🔍 Click here to explore your data
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          style={{ cursor: "pointer" }}
          onClick={e => {
            console.log(e.target.value);
            console.log("tables state ==>", projTables);
            e.target.value === "" || !e.target.value ? setProjTables("") : setProjTables(e.target.value);
          }}
          onChange={e => {
            console.log("selected dropdown value ==>", e.target.value);
            setProjTables(Traec.Im.Set());
          }}
        >
          {isRootRef ? (
            <option className="dropdown-item" value={"performance"}>
              🔢 Performance breakdown table
            </option>
          ) : null}
          <option className="dropdown-item" value={"metric_values"}>
            🔢 Input values table
          </option>
          {/* <option className="dropdown-item" value={"pie_chart"}>
            🥧 Metrics pie chart
          </option> */}
        </div>
      </div>

      <div className="row mb-2">
        <ErrorBoundary>{projTables === "pie_chart" ? <DoughnutPieChart /> : null}</ErrorBoundary>
      </div>

      <div className="row">
        <ErrorBoundary>
          {projTables === "metric_values" ? (
            <ProjectReportInputValues {...props} indicatorName={indicatorName} indicatorId={indicatorId} />
          ) : null}
        </ErrorBoundary>
      </div>

      <div className="row">
        <ErrorBoundary>
          {projTables === "performance" ? (
            <ProjectReportCommitResults
              {...props}
              indicatorOnly={true}
              indicatorName={indicatorName}
              indicatorId={indicatorId}
            />
          ) : null}
        </ErrorBoundary>
      </div>
    </React.Fragment>
  );
};

const getPreDispatchHook = props => {
  return action => {
    action.fetchParams.body = props.fetchBody;
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => dataToState(props, state, data);
    console.log("Calling dispatch for INDICATOR_DETAIL data", action);
    return action;
  };
};

// function DoughnutPieChart() {
//   const doughnutOptions = {
//     cutoutPercentage: 50,
//     animation: {
//       animateRotate: true,
//       animateScale: true
//     }
//   };

//   const doughnutData = {
//     datasets: [
//       {
//         data: [10, 40, 20, 30],
//         backgroundColor: [
//           "rgba(0, 204, 68, 0.5)",
//           "rgba(102, 153, 255, 0.5)",
//           "rgba(255, 26, 102, 0.5)",
//           "rgba(255, 128, 0, 0.5)"
//         ]
//       }
//     ],
//     labels: ["Metric 1", "Metric 2", "Metric 3", "Metric 4"]
//   };

//   return (
//     <React.Fragment>
//       <div className="col">
//         <Doughnut data={doughnutData} options={doughnutOptions} />
//       </div>
//     </React.Fragment>
//   );
// }

export default function IndicatorDetailPanel(props) {
  let { projectId, companyId, selected } = props;

  let indicator = selected?.size ? selected.toList().first() : null;

  return (
    <ErrorBoundary>
      <ErrorBoundary>
        <IndicatorHorizontalBarChart {...props} />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorVerticalBarChart {...props} />
      </ErrorBoundary>

      <ErrorBoundary>
        <ProjectContributions {...props} indicator={indicator} hide={!projectId} />
      </ErrorBoundary>

      <ErrorBoundary>
        <CompanyContributions {...props} indicator={indicator} hide={!companyId} />
      </ErrorBoundary>
    </ErrorBoundary>
  );
}
