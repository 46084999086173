import React from "react";

export const repPeriodChoices = ["MONTHS", "DAYS", "WEEKS", "QUARTERS", "YEARS"];

export const projectFields = {
  name: { value: "", class: "col", endRow: true },
  //address: { value: "", class: "col", endRow: true },
  //suburb: { value: "", class: "col" },
  //postcode: { value: "", class: "col" },
  //country: { value: "", class: "col", endRow: true },

  //client: {value:'', class: 'col-sm-8'},
  //NLA: {label: 'Project NLA', value:'', class: 'col', endRow: true},

  // Setting up from a template
  from_template: {
    label: "From Template",
    defaultValue: null,
    value: null,
    class: "col",
    inputType: "select",
    endRow: true,
    options: [
      <option key={1} value={""}>
        None
      </option>
    ]
  },
  reporting_from_template: {
    label: "Inherit Reporting, AuthGroups and Supplier Setup from Template",
    value: false,
    onChange: e => {
      e.preventDefault();
      console.log("PRESSED");
    },
    inputType: "checkbox",
    endRow: true
  },
  include_subcategories: {
    label: "Include Reporting Packages from Template",
    value: false,
    onChange: e => {
      e.preventDefault();
      console.log("PRESSED");
    },
    inputType: "checkbox",
    endRow: true
  },

  // Project reporting periods
  reporting_start: {
    label: "Reporting from: ",
    value: "",
    inputType: "date",
    class: "col"
  },
  reporting_end: {
    label: "Reporting to: ",
    value: "",
    inputType: "date",
    class: "col",
    endRow: true
  },
  reporting_freq: {
    label: "Every",
    value: 1,
    class: "col",
    inputType: "number"
  },
  reporting_units: {
    label: "Reporting Increments",
    value: "MONTHS",
    class: "col",
    inputType: "select",
    endRow: true,
    options: repPeriodChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    ))
  }
};

export const projectDetailsFields = {
  name: { value: "", class: "col", endRow: true }
  //address: { value: "", class: "col", endRow: true },
  //suburb: { value: "", class: "col" },
  //postcode: { value: "", class: "col" },
  //country: { value: "", class: "col", endRow: true }
};
